.root {
    width: 80vw;
    margin-left: 10vw;
    color: black;
}

.root p,
.root div,
.root h6,
.root ul,
.root li,
.root h1,
.root a {
    color: black
}



.root h1 {
    font-size: 60px;
    margin-top: 100px;


}

.root h6 {
    font-size: var(--text-md-one);
}

.root p,
.root li,
.root a,
.root div {
    font-size: var(--text-md-one)
}

.root p,
.root h6 {
    margin: var(--margin-large-one) 0px
}

ul:last-child {
    margin-bottom: var(--margin-large-one);
}



.section:last-child {
    margin-bottom: 100px;
}

.root .text__privacy {
    margin: 60px 0px;
}

@media screen and (max-width: 1096px) {
    .root h1 {
        font-size: var(--text-medium);
    }

    .root h6 {
        font-size: var(--text-md-one);
    }

    .section p,
    .root li,
    .root a {
        font-size: var(--text-md-two);
    }


}